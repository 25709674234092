import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { LocalStoreService } from '../services/localstore.service';
import { TranslateConfigService } from '../services/translate-config.service';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-cart-item-detail',
  templateUrl: './cart-item-detail.page.html',
  styleUrls: ['./cart-item-detail.page.scss'],
})
export class CartItemDetailPage implements OnInit {
  @Input() data: any ;
  itemDetail: any = [];
  currency = "¥";
  showDecimal= true;
  constructor(
    public modalCtrl: ModalController,
    private store:LocalStoreService,
    private translateConfigService: TranslateConfigService,
    private translate: TranslateService,
  ) { 
    this.translateConfigService.getDefaultLanguage();
    this.showDecimal= true;
    if(this.store.getCountry()) {
        let countryData = this.store.getCountry();
        this.currency   = countryData['master_country_currency_code'];
        if(countryData['master_country_currency_decimal_places'] == 0){
            this.showDecimal= false;
        }
    }
  }

  ngOnInit() {
    if(this.data) {
      this.itemDetail = this.data;
    }
  }

  dismissModal(){
    console.log("=====>")
    this.modalCtrl.dismiss();
  }
}
